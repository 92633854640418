import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { CATEGORY_DETAILS, SUBCATEGORY_DETAILS } from 'api/endpoints';
import { MatchCategoryType } from 'types/category';
import axios from 'utils/axios';

// ----------------------------------------------------------------------

type categoryObj = {
  name: string;
  player_base_price?: number | null;
};

type subCategoryObj = {
  name: string;
  parent_id: string | null;
};

const initialState: MatchCategoryType = {
  isLoading: false,
  hasError: null,
  matchCategories: [],
};
const slice = createSlice({
  name: 'matchCategories',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // SET CATEGORY
    setCategory(state, action) {
      state.isLoading = false;
      state.hasError = null;
      state.matchCategories = action.payload;
    },
    // DELETE CATEGORY
    setCategoryAfterDelete(state, action) {
      state.isLoading = false;
      state.hasError = null;
      state.matchCategories = action.payload;
    },
  },
});

// REDUCER
export default slice.reducer;

// ACTIONS
export const { startLoading, stopLoading, setCategory, setCategoryAfterDelete } = slice.actions;

// ----------------------------------------------------------------------

export function createCategory(
  categoryName: string,
  catBasePrice: number | null,
  seasonId: string
) {
  const categoryObj: categoryObj = {
    name: categoryName,
    ...(catBasePrice !== null && { player_base_price: catBasePrice }),
  };
  return async (dispatch: Dispatch<any>, getState: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(CATEGORY_DETAILS(seasonId), categoryObj);
      dispatch(
        setCategory([
          ...getState().matchCategories.matchCategories,
          {
            catName: response?.data?.data.name,
            id: response?.data?.data.id,
            basePlayerPrice: response?.data?.data.player_base_price,
          },
        ])
      );
      dispatch(getCategories(seasonId || ''));
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
}

export function createSubCategory(
  subCategoryName: string,
  subCatBasePrice: number | null,
  categoryId: string | null,
  seasonId: string
) {
  const subCategoryObj: subCategoryObj = {
    name: subCategoryName,
    parent_id: categoryId,
    ...(subCatBasePrice !== null && { player_base_price: subCatBasePrice }),
  };
  return async (dispatch: Dispatch<any>, getState: any) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(CATEGORY_DETAILS(seasonId), subCategoryObj);
      const updatedCategories = getState().matchCategories.matchCategories.map((cat: any) =>
        cat.id === categoryId
          ? {
            ...cat,
            matchSubCategories: [
              ...cat.matchSubCategories,
              {
                subCatId: response?.data?.data.id,
                subCatName: response?.data?.data.name,
                subCatBasePrice: response?.data?.data.base_player_price,
              },
            ],
          }
          : cat
      );
      dispatch(setCategory(updatedCategories));
      dispatch(getCategories(seasonId || ''));
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
}

export function getCategories(seasonId: string) {
  const categoriesList: any = [];
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(CATEGORY_DETAILS(seasonId));
      const topLevelCategories = response.data.data.filter((item: any) => item.parent === null);
      topLevelCategories.forEach((topLevelCategory: any) => {
        const matchSubCategories = response.data.data
          .filter((item: any) => item.parent && item.parent.id === topLevelCategory.uuid)
          .map((subCategory: any) => ({
            subCatId: subCategory.uuid,
            subCatName: subCategory.name,
            subCatBasePrice: subCategory.player_base_price,
          }));
        const categoryObj = {
          id: topLevelCategory.uuid,
          catName: topLevelCategory.name,
          basePlayerPrice: topLevelCategory.player_base_price,
          matchSubCategories,
        };
        categoriesList.push(categoryObj);
      });
      dispatch(setCategory(categoriesList));
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
}

export function deleteCategories(seasonId: string, categoryId: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      await axios.delete(SUBCATEGORY_DETAILS(seasonId, categoryId));
      dispatch(getCategories(seasonId || ''));
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
}

export function UpdateCategories(
  seasonId: string,
  categoryId: string,
  updatedName: string,
  updatedBasePrice: number | null
) {
  const updatedContent: any = {
    name: updatedName,
    player_base_price: updatedBasePrice,
  };
  return async (dispatch: Dispatch<any>) => {
    dispatch(startLoading());
    try {
      await axios.patch(
        SUBCATEGORY_DETAILS(seasonId, categoryId),
        updatedContent
      );
      dispatch(getCategories(seasonId || ''));
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
}
