import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { Organization, OrganizationType } from 'types/organization';
import { ORGANIZATION, ORGANIZATION_DETAILS, ORGANIZATION_IMAGE, ORGANIZATIONS_LIST } from 'api/endpoints';
// ----------------------------------------------------------------------

const initialState: OrganizationType = {
  isLoading: false,
  organizations: [],
  selectedDropDownOrg: '',
  currentOrganization: null,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // GET ORGANIZATIONS
    getOrganizations(state, action) {
      state.isLoading = false;
      state.organizations = action.payload ?? null;
    },
    // SET ORGANIZATION OPTION IN DROPDOWN
    setSelectedDropDownOrg(state, action) {
      state.selectedDropDownOrg = action.payload;
    },
    // SET ORGANIZATION LIST AFTER DELETING AN ORGANIZATION
    setOrganizationAfterDelete(state, action) {
      state.organizations =
        state.organizations?.filter((organization) => organization.id !== action.payload) ?? null;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  getOrganizations,
  setSelectedDropDownOrg,
  setOrganizationAfterDelete,
} = slice.actions;

// ----------------------------------------------------------------------

export function listOrganizations() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(ORGANIZATIONS_LIST);
      const data = response?.data.data;
      const modifiedData = data.map((org: any) => {
        const modifiedOrg = {
          id: org.id,
          name: org.name,
          profilePicture: org.profile_picture,
          description: org.description,
          coverImage: org.cover_image ?? null,
          defaultOrganization: org.is_default,
          createdAt: org.created_at,
        };
        if (org.is_default === true) {
          dispatch(setSelectedDropDownOrg(org.id));
        }
        return modifiedOrg;
      });
      dispatch(getOrganizations(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

interface CreateOrgBody {
  name: string;
  description: string;
  is_default?: boolean;
}

export function createOrganization(organization: Organization) {
  const createOrganizationBody: CreateOrgBody = {
    name: organization.name,
    description: organization.description,
  };
  if (organization.defaultOrganization) createOrganizationBody.is_default = true;
  else createOrganizationBody.is_default = false;
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(ORGANIZATION, createOrganizationBody);
      const id = response?.data?.data?.id;
      return id;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function editOrganization(
  organization: {
    description?: string;
    defaultOrganization?: boolean;
    id?: string;
    is_default?: boolean;
  },
  orgId: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      if (organization.defaultOrganization) {
        organization.is_default = true;
        delete organization.defaultOrganization;
      }
      await axios.patch(ORGANIZATION_DETAILS(orgId), organization);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deleteOrganization(organizationId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.delete(ORGANIZATION_DETAILS(organizationId));
      dispatch(setOrganizationAfterDelete(organizationId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function changeOrganizationImg(organizationId: string, coverImage: File) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const form = new FormData();
      form.append('cover_image', coverImage);
      await axios.post(ORGANIZATION_IMAGE(organizationId), form);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
