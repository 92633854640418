import { AnyAction, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import organizationReducer from './slices/organization';
import profileReducer from './slices/user';
import tournamentReducer from './slices/tournaments';
import seasonReducer from './slices/seasons';
import teamReducer from './slices/team';
import playerReducer from './slices/player';
import matchCategoryReducer from './slices/category';
import auctionReducer from './slices/auction';
import dashboardReducer from './slices/dashboard';
import invitationReducer from './slices/invitation';

// ----------------------------------------------------------------------

export const RESET_STATE = 'RESET_STATE';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
  organization: organizationReducer,
  profile: profileReducer,
  tournament: tournamentReducer,
  season: seasonReducer,
  team: teamReducer,
  player: playerReducer,
  matchCategories: matchCategoryReducer,
  auction: auctionReducer,
  dashboard: dashboardReducer,
  invitation: invitationReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
