import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function convertToUTC(data: Date | string) {
  const istDate = new Date(data);
  const utcString = istDate.toISOString();
  return utcString;
}

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

export function formatDateToDDMMYYYY(isoDate: any) {
  const date = new Date(isoDate);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export function subtractOneDay(isoDate: any) {
  const date = new Date(isoDate);
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const newDate = new Date(date.getTime() - oneDayInMilliseconds);
  return newDate.toISOString(); // Returning the date in ISO 8601 format
}