import { ChangeEvent, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar';
import { useDispatch, useSelector } from 'redux/store';
import { PATH_MODULES } from 'routes/paths';
import { listOrganizations, setSelectedDropDownOrg } from 'redux/slices/organization';

export default function OrganizationSelect() {
  const { organizations, selectedDropDownOrg } = useSelector((state) => state.organization);
  const dispatch = useDispatch();

  const [selectedOrganization, setSelectedOrganization] = useState(selectedDropDownOrg);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!organizations?.length) {
      (async () => {
        try {
          await dispatch(listOrganizations());
        } catch (err) {
          enqueueSnackbar(err.data, { variant: 'error' });
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropDownValueChange = async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value;
    await dispatch(setSelectedDropDownOrg(newValue));
    navigate(PATH_MODULES.tournaments.root);
  }

  useEffect(() => {
    setSelectedOrganization(selectedDropDownOrg);
  }, [selectedDropDownOrg]);

  return (
    <TextField
      id="select-organization"
      select
      name="select-organization"
      value={selectedOrganization}
      label="Organization"
      style={{
        paddingTop: '0px',
        paddingBottom: '0px',
      }}
      sx={{
        marginTop: {
          xs: '15px',
          lg: '0',
        },
        width: {
          md: '150px',
          sx: 'auto'
        }
      }}
      onChange={dropDownValueChange}
    >
      {organizations?.map((organization) => (
        <MenuItem key={organization.id} value={organization.id}>
          {organization.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
