import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { ACCEPT_INVITE, GET_INVITATION_DETAILS, REJECT_INVITE } from 'api/endpoints';
import { InvitationLetter } from 'types/invitation';

const initialState: InvitationLetter = {
  isLoading: true,
  hasError: null,
  content: {
    uuid: '',
    message: '',
    expireIn: '',
    season: {
      id: '',
      name: '',
      location: '',
      cover_image: null,
      tournament: '',
      startDate: '',
      endDate: '',
      noOfTeams: 0,
      playersPerTeam: 0,
    },
    inviteeType: 2,
    autoApprove: false,
    invitationType: 1,
    maxPlayers: null,
    invitee: '',
    invitationStatus: null,
    tournament: {
      name: '',
      createdBy: '',
    },
  },
  inviteId: null,
  redirectTo: null,
};

const slice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.hasError = null;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.hasError = null;
    },
    // GET INVITATION DETAILS
    getDetails(state, action) {
      state.isLoading = false;
      state.content = action.payload;
    },
    setInviteDetails(state, action) {
      state.inviteId = action.payload.inviteId;
      state.redirectTo = action.payload.redirectTo;
    },
    clearInviteDetails(state) {
      state.inviteId = null;
      state.redirectTo = null;
    }
  },
});

export default slice.reducer;

export const { startLoading, stopLoading, getDetails, setInviteDetails, clearInviteDetails } = slice.actions;

// ----------------------------------------------------------------------

export function getInvitationDetails(invitationId: string) {
  return async (dispatch: Dispatch) => {
    try {
      startLoading();
      const response = await axios.get(GET_INVITATION_DETAILS(invitationId));
      const responseData = response?.data?.data;
      const {
        expire_in,
        invitee_type,
        auto_approve,
        invitation_type,
        max_players,
        invitation_status,
        ...rest
      } = responseData;

      const modifiedTournament = {
        name: responseData?.tournament?.name,
        createdBy: responseData?.tournament?.created_by[0]?.name,
        id: responseData?.tournament?.id,
      };

      const modifiedInvitationData = {
        ...rest,
        expireIn: expire_in,
        inviteeType: invitee_type,
        autoApprove: auto_approve,
        invitationType: invitation_type,
        maxPlayers: max_players,
        invitationStatus: invitation_status,
        tournament: modifiedTournament,
      };

        const seasonResponseData = response?.data?.data.season;
        const { cover_image, start_date, end_date, no_of_teams, players_per_team, uuid, ...seasonRest } =
          seasonResponseData;
        const modifiedSeasonData = {
          ...seasonRest,
          coverImage: cover_image,
          startDate: start_date,
          endDate: end_date,
          noOfTeams: no_of_teams,
          playersPerTeam: players_per_team,
        };

        const modifiedResponse = {
          ...modifiedInvitationData,
          season: modifiedSeasonData,
        };
      dispatch(getDetails(modifiedResponse));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function updateInvitationStatus(id: string, action: string) {
  const payload = { invitation_id: id };
  return async (dispatch: Dispatch) => {
    try {
      if(action === 'accept')
        await axios.post(ACCEPT_INVITE, payload);
      else
       await axios.post(REJECT_INVITE, payload);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
