import axios from 'axios';

import { HOST_API_KEY, HOST_PASSWORD, HOST_USERNAME } from '../config-global';

export const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

const refreshToken = async () => {
  delete axiosInstance.defaults.headers.common.Authorization;
  await axiosInstance
    .post('api/users/token/refresh/', {
      refresh: localStorage.getItem('refreshToken'),
    })
    .then((response) => {
      const newToken = response?.data?.data?.access;
      localStorage.setItem('accessToken', newToken);
      return newToken;
    })
    .catch((error) => {
      throw error;
    });
};

const username = HOST_USERNAME;
const password = HOST_PASSWORD;

const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;;
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = basicAuth;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshToken();
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'accessToken'
      )}`;
      originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
      return axios(originalRequest);
    }

    // if (error.response.status === 500) {
    //   console.log('axios error is 500');
    // navigateTo(PATH_PAGE.page500);

    // }

    // if (error.response.status === 403) {
    //   navigate(PATH_PAGE.page403, { replace: true });
    // }

    // if (error.response.status === 404) {
    //   navigate(PATH_PAGE.page404, { replace: true });
    // }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
