// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ORGANIZATION = '/organization';
const ROOTS_USER = '/user';
const ROOTS_PLAYER = '/player';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  registerPasswordless: path(ROOTS_AUTH, '/register-passwordless')
};

export const PATH_USER = {
  profile: path(ROOTS_USER, '/profile'),
  edit_sports_detail: path(ROOTS_USER, '/sports-detail'),
  account: (name: string) => path(ROOTS_USER, `/account/${name.toLowerCase()}`),
};

export const PATH_PLAYER = {
  profile: (id: string) => path(ROOTS_PLAYER, `/${id.toLowerCase()}/profile`),
  edit_sports_detail: (id: string) => path(ROOTS_PLAYER, `${id.toLowerCase()}/sports-detail`),
  bids: (id: string) => path(ROOTS_PLAYER, `/${id}/bids-history`),
};

export const PATH_PAGE = {
  landingPage: '/',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  payment: '/payment',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
};

export const PATH_MODULES = {
  organization: {
    root: '/organization',
    new: path(ROOTS_ORGANIZATION, '/new'),
    edit: (id: string) => path(ROOTS_ORGANIZATION, `/edit/${id}`),
  },
  tournaments: {
    root: '/tournaments',
    edit: (tournamentId: string) => `/tournament/${tournamentId}/edit`,
    new: `/tournament/new`,
  },
  seasons: {
    root: (tournamentId: string) => `/tournaments/${tournamentId}/seasons`,
    edit: (tournamentId: string, seasonId: string) =>
      `/tournaments/${tournamentId}/seasons/${seasonId}/edit`,
    new: (tournamentId: string) => `/tournaments/${tournamentId}/seasons/new`,
    view: (tournamentId: string, seasonId: string) =>
      `/tournaments/${tournamentId}/seasons/${seasonId}`,
    welcome: (tournamentId: string, seasonId: string) =>
      `/tournaments/${tournamentId}/seasons/${seasonId}/welcome`,
  },
  teams: {
    createTeamFromNavbar: '/teams',
    addTeamPlayersFromNavbar: (teamId: string) => `/teams/${teamId}/players/add`,
    addTeamPlayersFromSeason: (seasonId: string, teamId: string) =>
      `/seasons/${seasonId}/teams/${teamId}/players/add`,
    view: (teamId: string) => `/teams/${teamId}`,
    viewSeasonTeams: (seasonId: string, teamId: string) => `/seasons/${seasonId}/teams/${teamId}  `,
    new: (seasonId: string) => `/seasons/${seasonId}/teams/new`,
    edit: (seasonId: string, teamId: string) => `/seasons/${seasonId}/teams/${teamId}/edit`,
    invite: (seasonId: string) => `/seasons/${seasonId}/teams/invite`,
  },
  players: {
    new: (seasonId: string) => `/seasons/${seasonId}/players/new`,
    view: (title: string) => path(`/players/`, `${title}`),
    invite: (seasonId: string) => `/seasons/${seasonId}/players/invite`,
  },
  matches: {
    new: path('/matches', '/new'),
  },
  auction: {
    new: (seasonId: string) => `/seasons/${seasonId}/auction/new`,
    edit: (seasonId: string, auctionId: string) => `/seasons/${seasonId}/auction/${auctionId}`,
    live: (auctionId: string, view: string) => `/live-auction/${auctionId}/${view}`,
  },
  category: {
    new: (seasonId: string) => `/seasons/${seasonId}/category/new`,
    CategoryPlayers: (seasonId: string, categoryId: string) =>
      `/seasons/${seasonId}/category/${categoryId}/players`,
    SubCategoryPlayers: (seasonId: string, subCategoryId: string | null) =>
      `/seasons/${seasonId}/subCategory/${subCategoryId}/players`,
  },
  // invitation: {
  //   root: '/invitation',
  // },
};

export const PATH_INVITATION = {
  root: '/publicInvitation',
};

// http://localhost:8080/invitation?code=a6368943-5c1e-442b-9835-4517783e2488&type=team
