import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IUserAccountDetails, IUserSports, PlayerDets, SportLabel } from 'types/user';
import {
  PLAYER_DETAILS,
  PROFILE_IMAGE,
  RESEND_OTP,
  MY_SPORT_DETAILS,
  USER_DETAILS,
  VERIFY_NUMBER,
  VERIFY_OTP,
  GET_SPORT_LABELS,
} from 'api/endpoints';
import axios from 'utils/axios';
// ----------------------------------------------------------------------

interface IUserAccountDetailsType {
  isLoading: boolean;
  sportsLoading: boolean;
  user: IUserAccountDetails;
  sportsList: IUserSports[] | null;
  playerDetails: PlayerDets | null;
  sportLabels: SportLabel[];
  sportsDetails: object;
}

const initialState: IUserAccountDetailsType = {
  user: {
    username: '',
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
    profilePicture: null,
    phoneNumber: '',
    about: '',
    teams: null,
    role: 'user',
    uuid: '',
  },
  sportsList: null,
  isLoading: false,
  sportsLoading: false,
  playerDetails: null,
  sportLabels: [],
  sportsDetails: {},
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING SPORTS
    startSportsLoading(state) {
      state.sportsLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // SET ACCOUNT DETAILS
    setAccount(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    // SAVE ANOTHER PLAYER DETAILS
    savePlayerDetails(state, action) {
      state.playerDetails = action.payload;
    },
    // SET SPORTS LIST OF USER
    setSportsList(state, action) {
      state.isLoading = false;
      state.sportsLoading = false;
      state.sportsList = action.payload;
    },
    // SET SPORT LABELS
    setSportLabels(state, action) {
      state.isLoading = false;
      state.sportLabels = action.payload;
    },
    // SET SPORTS DETAILS
    setSportsDetails(state, action)
    {
      state.isLoading = false;
      state.sportsLoading = false;
      state.sportsDetails = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  startSportsLoading,
  stopLoading,
  setAccount,
  savePlayerDetails,
  setSportsList,
  setSportLabels,
  setSportsDetails
} = slice.actions;

// ----------------------------------------------------------------------

export function getAccountDetails() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(USER_DETAILS);
      const userDetails = response.data?.data;
      const user = {
        username: userDetails?.username ?? userDetails?.first_name ?? 'Anonymous',
        firstName: userDetails?.first_name,
        lastName: userDetails?.last_name,
        displayName: `${userDetails?.first_name ?? ''} ${userDetails?.last_name ?? ''}`,
        email: userDetails?.email,
        profilePicture: userDetails?.profile_picture || null,
        phoneNumber: userDetails?.phone_number,
        teams: userDetails?.team,
        role: userDetails?.role || 'user',
        about: userDetails?.about || '',
        uuid: userDetails?.uuid || '',
      };
      dispatch(setAccount(user));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getPlayer(playerId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(PLAYER_DETAILS(playerId));
      const data = response?.data?.data;
      const modifiedData = {
        id: data.id,
        username: data.username,
        fullname: data.fullname,
        phoneNumber: data.phone_number,
        profilePicture: data.profile_picture,
        sports: data.sports.map((sport: any) => {
          const modSport = {
            sportId: sport.sport,
            sportName: sport.sport,
            details: sport.details,
          };
          return modSport;
        }),
        about: data.about,
        category: data.category,
        subCategory: data.subCategory,
        teams: data.teams,
      };
      dispatch(savePlayerDetails(modifiedData));
      dispatch(setSportsList(modifiedData.sports));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function editAccountDetails(data: any) {
  return async (dispatch: Dispatch) => {
    const editedData = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      about: data.about,
    };
    try {
      dispatch(startLoading());
      const response = await axios.patch(USER_DETAILS, editedData);
      const userDetails = response.data?.data;
      const user = {
        username: userDetails?.username ?? userDetails?.first_name ?? 'Anonymous',
        firstName: userDetails?.first_name,
        lastName: userDetails?.last_name,
        displayName: `${userDetails?.first_name ?? ''} ${userDetails?.last_name ?? ''}`,
        email: userDetails?.email,
        profilePicture: userDetails?.profile_picture || null,
        phoneNumber: userDetails?.phone_number,
        teams: userDetails?.team,
        role: userDetails?.role || 'user',
        about: userDetails?.about || '',
      };
      await dispatch(setAccount(user));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function setUserSportsDetails(sportid:number, data:any) {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post(
        '/api/users/sports/',
        {sport: sportid, details: data}
      );
      dispatch(setSportsList(response.data?.data.sports.map((sport: any) => {
        const modifiedSport = {
          details: sport.details,
          sportId: sport.sport_id,
          sportName: sport.sport_name,
        };
        return modifiedSport;
      })));
    } catch (error) {
      dispatch(stopLoading);
      throw(error)
    }
  };
}

export function editUserSportsDetails(sportid:number, data:any) {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(
        '/api/users/sports/',
        {sport: sportid, updates: data}
      );
      dispatch(setSportsList(response.data?.data.sports.map((sport: any) => {
        const modifiedSport = {
          details: sport.details,
          sportId: sport.sport_id,
          sportName: sport.sport_name,
        };
        return modifiedSport;
      })));
    } catch (error) {
      dispatch(stopLoading);
      throw(error)
    }
  };
}

export function getSportsDetailsOfUser() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startSportsLoading());
      const response = await axios.get(MY_SPORT_DETAILS);
      const sportData = response?.data?.data?.[0]?.sports;
      const restructuredData = sportData?.map((sport: any) => {
        const modifiedSport = {
          details: sport.details,
          sportId: sport.sport_id,
          sportName: sport.sport_name,
        };
        return modifiedSport;
      });
      dispatch(setSportsList(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getSportLabels() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(GET_SPORT_LABELS);
      const data = response?.data?.data;
      const sportLabels = data.map((sportData: any) => {
        const { sport_id, sport_name, ...rest } = sportData;

        const sportFields = sportData.fields.map((field: any) => ({
          fieldName: field.field_name,
          fieldLabel: field.field_label,
          fieldType: field.field_type,
          fieldValues: field.field_values.map((value: any) => ({
            value: Object.keys(value)[0],
            label: Object.values(value)[0],
          })),        }));
        return {
          sportId: sport_id,
          sportName: sport_name,
          fields: sportFields,
        };
      });
      dispatch(setSportLabels(sportLabels));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function verifyNumber(phone_number: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(VERIFY_NUMBER, { phone_number });
      return response;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function verifyOTP(phone_number: string, otp: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(VERIFY_OTP, { phone_number, otp });
      return response;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function resendOTP(phone_number: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(RESEND_OTP, { phone_number });
      return response;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function setProfilePic(profile_picture: File) {
  const form = new FormData();
  form.append('profile_picture', profile_picture);
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(PROFILE_IMAGE, form);
      const userDetails = response.data?.data;
      const user = {
        username: userDetails?.username ?? userDetails?.first_name ?? 'Anonymous',
        firstName: userDetails?.first_name,
        lastName: userDetails?.last_name,
        displayName: `${userDetails?.first_name ?? ''} ${userDetails?.last_name ?? ''}`,
        email: userDetails?.email,
        profilePicture: userDetails?.profile_picture || null,
        phoneNumber: userDetails?.phone_number,
        teams: userDetails?.team,
        role: userDetails?.role || 'user',
        about: userDetails?.about || '',
      };
      await dispatch(setAccount(user));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
