import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { DASHBOARD_DETAILS } from 'api/endpoints'
import axios from 'utils/axios';
// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    dashboardDetails: {
        "completedSeasons": 0,
        "upcomingSeasons": 0,
        "registeredTournaments": 0,
        "applications": 0
    }
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // STOP LOADING
        stopLoading(state) {
            state.isLoading = false;
        },
        // SET DASHBOARD DETAILS
        setDashboardDetails(state, action) {
            state.isLoading = false;
            state.dashboardDetails = action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, setDashboardDetails } = slice.actions;

// ----------------------------------------------------------------------
export function getDashboardDetails() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(startLoading());
            const response = await axios.get(DASHBOARD_DETAILS);
            const dashboardDetails = response.data?.data;
            const modifiedData = {
                "completedSeasons": dashboardDetails?.completed_seasons,
                "upcomingSeasons": dashboardDetails?.upcoming_seasons,
                "registeredTournaments": dashboardDetails?.registered_tournaments,
                "applications": dashboardDetails?.applications
            }
            dispatch(setDashboardDetails(modifiedData));
        } catch (err) {
            dispatch(stopLoading());
            throw err;
        }
    };
}