import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  ADD_PLAYER_TO_TEAM,
  GENERATE_URL,
  INVITE_TEAMS,
  LIST_INVITATIONS,
  RESET_URL,
  SEASON_TEAM_PLAYERS,
  TEAM_DETAILS,
  TEAM_IMAGE,
  TEAM_REGISTRATION_STATUS,
  TEAM_UNDER_MANAGER,
  TEAMS,
  TEAMS_IN_SEASON,
  TEAMS_UNDER_MANAGER,
  UPDATE_URL,
} from 'api/endpoints';
import { TeamType, TeamContents } from 'types/team';
import axios from 'utils/axios';

// ----------------------------------------------------------------------

type AddEditTeam = Omit<TeamContents, 'managers' | 'players' | 'id' | 'seasonIdNo'>;

const initialState: TeamType = {
  isLoading: false,
  hasError: null,
  teamInviteLink: '',
  invitedTeams: [],
  teams: [],
  team: {
    id: '',
    name: '',
    location: '',
    managers: [
      {
        id: '',
        phoneNumber: '',
        email: '',
        fullName: '',
        role: 0,
      },
    ],
    teamPlayers: [
      {
        player: {
          id: '',
          fullName: '',
          phoneNumber: '',
          profilePicture: '',
        },
      },
    ],
    coverImage: null,
  },
  teamsOfAManager: [],
  teamsPartOf: [],
  currentTab: 'managers',
  teamRegistration:[],
};
const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.hasError = null;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.hasError = null;
    },
    // GET TEAMS
    getTeams(state, action) {
      state.isLoading = false;
      state.teams = action.payload;
    },
    // GET A TEAM
    getATeam(state, action) {
      state.isLoading = false;
      state.team = action.payload;
    },
    // GET TEAMS UNDER A MANAGER
    getTeamsOfAManager(state, action) {
      state.isLoading = false;
      state.teamsOfAManager = action.payload;
    },
    // GET TEAMS THE USER IS PART OF
    getTeamsPartOf(state, action) {
      state.isLoading = false;
      state.teamsPartOf = action.payload;
    },
    // DELETE TEAMS
    setTeamsAfterDelete(state, action) {
      state.isLoading = false;
      state.teams = state.teams.filter((team) => team.id !== action.payload);
    },
    // SET TEAM INVITE LINK
    setTeamInviteLink(state, action) {
      state.isLoading = false;
      state.teamInviteLink = action.payload;
    },
    // GET TEAM INVITATION DETAILS
    getTeamInvitationDetails(state, action) {
      state.isLoading = false;
      state.invitedTeams = action.payload;
    },
    // SET CURRENT TAB IN TEAM DETAILS PAGE
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    teamRegistrationStatus(state, action){
      state.isLoading = false;
      state.teamRegistration = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  getTeams,
  getATeam,
  getTeamsOfAManager,
  getTeamsPartOf,
  setTeamsAfterDelete,
  setTeamInviteLink,
  getTeamInvitationDetails,
  setCurrentTab,
  teamRegistrationStatus,
} = slice.actions;

// ----------------------------------------------------------------------

export function createTeam(team: AddEditTeam) {
  const createTeamBody: any = {
    name: team.name,
    location: team.location,
  };
  return async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch(startLoading());
      const teamCreateResponse = await axios.post(TEAMS, createTeamBody);
      await dispatch(
        getTeamsOfAManager([...getState().team.teamsOfAManager, teamCreateResponse?.data.data])
      );
      const id = teamCreateResponse?.data?.data?.id;
      return id;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function editTeam(team: AddEditTeam, teamId: string) {
  const createTeamBody: Omit<AddEditTeam, 'totalPlayers' | 'coverImage'> = {
    name: team.name,
    location: team.location,
  };
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      await axios.patch(TEAM_DETAILS(teamId), createTeamBody);
      await dispatch(listTeamsUnderAManager());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function applyTeamImage(teamId: String, image: File) {
  const form = new FormData();
  form.append('cover_image', image);
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(TEAM_IMAGE(teamId), form);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deleteTeamUnderAManager(teamId: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      await axios.delete(TEAM_UNDER_MANAGER(teamId));
      dispatch(listTeamsUnderAManager());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function addTeamToASeason(seasonId: string, id: string[]) {
  const teamDetails: any = {
    team_id: id,
  };
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      await axios.post(TEAMS_IN_SEASON(seasonId), teamDetails);
      dispatch(listTeamsOfSeason(seasonId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deleteTeamFromASeason(teamId: string, seasonId: string) {
  const deleteTeam: any = {
    team_id: teamId,
  };
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      await axios.delete(TEAMS_IN_SEASON(seasonId), {
        data: deleteTeam,
      });
      dispatch(listTeamsOfSeason(seasonId || ''));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deletePlayerFromSeasonTeam(playerId: string, seasonId: string, teamId: string) {
  const deletePlayer: any = {
    player_id: playerId,
    season_id: seasonId,
  };
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      await axios.delete(ADD_PLAYER_TO_TEAM, {
        data: deletePlayer,
      });
      dispatch(getTeamDetails(teamId, seasonId || ''));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listTeamsOfSeason(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(TEAMS_IN_SEASON(seasonId));
      const responseData = response.data.data;
      const restructredResponseData = responseData.map((team: any) => {
        const { total_players, cover_image, ...rest } = team;
        const modifiedData = { ...rest, totalPlayers: total_players, coverImage: cover_image };
        return modifiedData;
      });
      dispatch(getTeams(restructredResponseData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function inviteTeams(
  seasonId: string,
  formattedExpireIn: string,
  isEmail: boolean,
  message: string,
  emailOrPhoneArray?: string[],
  subject?: string
) {
  const teamInvitation: any = {
    season_id: seasonId || '',
    invitee_type: 1,
    invitation_type: isEmail ? 1 : 2,
    [isEmail ? 'emails' : 'phone_numbers']: emailOrPhoneArray,
    message,
    subject,
    expire_in: formattedExpireIn,
  };

  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(INVITE_TEAMS, teamInvitation);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listTeamInvitations(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(LIST_INVITATIONS(1, seasonId || ''));

      const modifiedSmsData = response?.data?.data.sms.map((smsItem: any) => ({
        uuid: smsItem.uuid,
        phoneNumber: smsItem.phone_number,
        status: smsItem.status,
      }));
      const teamMailData = response?.data?.data.mail.map((mailItem: any) => ({
        uuid: mailItem.uuid,
        email: mailItem.email,
        status: mailItem.status,
      }));

      const restructredResponseData = {
        mail: teamMailData,
        sms: modifiedSmsData,
        link: [],
      };
      dispatch(getTeamInvitationDetails([restructredResponseData]));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listTeamsUnderAManager() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const responseM = await axios.get(TEAMS_UNDER_MANAGER);
      const restructuredData = responseM?.data.data.map((response: any) => {
        const { cover_image, ...rest } = response;
        const modifiedData = { ...rest, coverImage: cover_image };
        return modifiedData;
      });
      dispatch(getTeamsOfAManager(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listTeamsPartOf() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(TEAMS_UNDER_MANAGER, { params: { role: 'player' } });
      const restructuredData = response?.data.data.map(({ cover_image, ...rest }: any) => ({
        ...rest,
        coverImage: cover_image
      }));
      dispatch(getTeamsPartOf(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getTeamDetails(teamId: string, seasonId?: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const responseM = await axios.get(TEAM_DETAILS(teamId));
      let respsonseSeasonTeam;
      if (seasonId) {
        respsonseSeasonTeam = await axios.get(SEASON_TEAM_PLAYERS, {
          params: { season_id: `${seasonId}`, team_id: `${teamId}` },
        });
      }
      const responseData = responseM.data.data;
      const responseDataSeasonTeam = respsonseSeasonTeam?.data?.data;

      const restructuredManagersData =
        responseData?.managers?.map((manager: any) => {
          const {
            user: { phone_number, first_name, last_name, profile_picture },
          } = manager;

          const modifiedManagerData = {
            phoneNumber: phone_number,
            fullName: last_name ? `${first_name} ${last_name}` : first_name,
            profilePicture: profile_picture,
          };

          return modifiedManagerData;
        }) || [];

      const restructuredTeamPlayersData =
        responseData?.team_players?.map((player: any) => {
          const {
            player: { id, first_name, last_name, phone_number, profile_picture },
          } = player;

          const modifiedPlayerData = {
            id,
            fullName: last_name ? `${first_name} ${last_name}` : first_name,
            phoneNumber: phone_number,
            profilePicture: profile_picture,
          };
          return modifiedPlayerData;
        }) || [];

      const restructuredSeasonTeamPlayersData = seasonId
        ? responseDataSeasonTeam?.map((player: any) => {
            const {
              player: { id, first_name, last_name, phone_number, profile_picture },
            } = player;

            const modifiedPlayerData = {
              id,
              fullName: last_name ? `${first_name} ${last_name}` : first_name,
              phoneNumber: phone_number,
              profilePicture: profile_picture,
            };
            return modifiedPlayerData;
          }) || []
        : restructuredTeamPlayersData;

      const modifiedTeamData = {
        id: responseData.id,
        name: responseData.name,
        location: responseData.location,
        managers: restructuredManagersData,
        teamPlayers: restructuredSeasonTeamPlayersData ?? restructuredTeamPlayersData,
        coverImage: responseData.cover_image,
      };

      dispatch(getATeam(modifiedTeamData));
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function generateURLForTeam(
  seasonId: string,
  expireDateFormatted: string,
  autoApprove?: boolean,
  maxPlayers?: number
) {
  const detailsToGenLink = {
    season_id: seasonId,
    expire_in: expireDateFormatted,
    invitee_type: 1,
    invitation_type: 3,
    auto_approve: autoApprove,
    max_players: maxPlayers,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(GENERATE_URL, detailsToGenLink);
      dispatch(setTeamInviteLink(response?.data?.data?.link));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function updateInvitationLink(
  data: any
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.patch(UPDATE_URL, data);
      console.log(response);
      // dispatch(setTeamInviteLink(response?.data?.data?.link));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function ResetTeamURL(
  seasonId: string,
  expireDateFormatted: string,
  inviteeType: number,
  autoApprove?: boolean,
  maxPlayers?: number
) {
  const detailsToGenLink = {
    season_id: seasonId,
    expire_in: expireDateFormatted,
    invitee_type: inviteeType,
    invitation_type: 3,
    auto_approve: autoApprove,
    max_players: maxPlayers,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(RESET_URL, detailsToGenLink);
      dispatch(setTeamInviteLink(response?.data?.data?.link));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getTeamRegistrationStatus(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(TEAM_REGISTRATION_STATUS(seasonId));
      const modifiedData = response?.data?.data.map((reg: any) => {
        const { invitation_id, ...rest } = reg;
        const modifiedReg = {
          ...rest,
          invitationId: invitation_id,
        };
        return modifiedReg;
      });
      dispatch(teamRegistrationStatus(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
