import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  LIST_INVITATION_LINKS,
  SEASON_AUCTION_LIST,
  SEASON_DETAILS,
  SEASON_IMAGE,
  SEASON_RULES,
  SEASONS,
  UNAUCTIONED_UNSOLD_SEASON_PLAYERS,
} from 'api/endpoints';
import { SeasonType, AddEditSeason } from 'types/season';
import axios from 'utils/axios';

// ----------------------------------------------------------------------

type seasonDetails = Omit<AddEditSeason, 'id' | 'cover_image'>;
// type editDetails = Omit<AddEditSeason, 'tournament_id' | 'tournament'>;

const initialState: SeasonType = {
  isLoading: false,
  seasons: [],
  season: {
    name: '',
    location: '',
    tournamentId: '',
    tournament: undefined,
    startDate: '',
    endDate: '',
    numberOfTeams: 0,
    playersPerTeam: 0,
    coverImage: null,
  },
  seasonIdNo: '',
  auction: null,
  currentTab: 'rules',
  linkHistory: [],
  unAuctionedPlayers: [],
  totalUnAuctionedPlayers: 0,
  seasonRules: '',
};
const slice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // GET SEASONS
    getSeasons(state, action) {
      state.isLoading = false;
      state.seasons = action.payload;
    },
    // GET SEASON
    getASeason(state, action) {
      state.isLoading = false;
      state.season = action.payload;
    },
    // DELETE SEASON
    setSeasonAfterDelete(state, action) {
      state.isLoading = false;
      state.seasons = state.seasons.filter((season) => season.id !== action.payload);
    },
    // SET SEASON ID
    setSeasonId(state, action) {
      // state.season_id = state.seasons.find(season => String(season.name).replace(/\s/g, '').toLowerCase() === action.payload.seasonName.replace(/\s/g, '').toLowerCase())?.id
      state.seasonIdNo = action.payload;
    },
    // SET AUCTIONS
    setAuctions(state, action) {
      state.isLoading = false;
      if (action.payload) state.auction = action.payload;
      else state.auction = null;
    },
    // SET CURRENT TAB IN SEASON DETAILS PAGE
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    // SET HISTORY OF LINKS
    setLinkHistory(state, action) {
      state.linkHistory = action.payload;
      state.isLoading = false;
    },
    // SET UNAUCTIONED PLAYERS OF SEASON
    getUnAuctionedPlayers(state, action) {
      state.unAuctionedPlayers = action.payload;
      state.isLoading = false;
    },
    // SET UNAUCTIONED PLAYERS OF SEASON
    setUnAuctionedPlayersCount(state, action) {
      state.totalUnAuctionedPlayers = action.payload;
      state.isLoading = false;
    },
    setSeasonRules(state, action) {
      state.seasonRules = action.payload;
      state.isLoading = false;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  getSeasons,
  getASeason,
  setSeasonAfterDelete,
  setSeasonId,
  setAuctions,
  setCurrentTab,
  setLinkHistory,
  getUnAuctionedPlayers,
  setUnAuctionedPlayersCount,
  setSeasonRules,
} = slice.actions;

// ----------------------------------------------------------------------

export function createSeason(season: seasonDetails) {
  if(season.rules && ['<ul><li><br></li></ul>'].includes(season.rules))
    season.rules = '';
  const createSeasonBody: any = {
    name: season.name,
    location: season.location,
    tournament_id: season.tournament_id,
    tournament: season.tournament,
    start_date: season.startDate,
    end_date: season.endDate,
    no_of_teams: season.numberOfTeams,
    players_per_team: season.playersPerTeam,
    rules: season.rules,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(SEASONS, createSeasonBody);
      dispatch(stopLoading());
      const id = response?.data?.data?.id;
      return id;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listSeasons(tournamentId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(SEASONS, { params: { tournament: tournamentId } });
      const restructuredData = response.data?.data?.map((season: any) => {
        const { cover_image, start_date, end_date, no_of_teams, players_per_team, ...rest } =
          season;
        const modifiedSeason = {
          ...rest,
          coverImage: cover_image,
          startDate: start_date,
          endDate: end_date,
          numberOfTeams: no_of_teams,
          playersPerTeam: players_per_team,
        };
        return modifiedSeason;
      });
      dispatch(getSeasons(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getSeason(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(SEASON_DETAILS(seasonId));
      const { cover_image, start_date, end_date, no_of_teams, players_per_team, ...rest } =
        response.data.data;
      const dataWithRenamedCoverImage = {
        ...rest,
        coverImage: cover_image,
        startDate: start_date,
        endDate: end_date,
        numberOfTeams: no_of_teams,
        playersPerTeam: players_per_team,
      };
      dispatch(getASeason(dataWithRenamedCoverImage));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function editSeason(season: AddEditSeason) {
  if(season.rules && ['<ul><li><br></li></ul>'].includes(season.rules))
    season.rules = '';
  const editSeasonBody: {
    id: string | undefined;
    name: string | number;
    location: string;
    start_date: string | Date;
    end_date: string;
    no_of_teams: number | string;
    players_per_team: number;
    rules?: string;
  } = {
    id: season.id,
    name: season.name,
    location: season.location,
    start_date: season.startDate,
    end_date: season.endDate,
    no_of_teams: season.numberOfTeams,
    players_per_team: season.playersPerTeam,
    rules: season.rules,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.patch(SEASON_DETAILS(season.id), editSeasonBody);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deleteSeason(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.delete(SEASON_DETAILS(seasonId));
      dispatch(setSeasonAfterDelete(seasonId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getAuctions(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      // dispatch(startLoading()); this loading is affecting season details page loader
      const response = await axios.get(SEASON_AUCTION_LIST(seasonId),);
      const auctionData = response.data.data;

      if (!auctionData) {
        // Handle the case where data is null
        dispatch(setAuctions(null));
        return;
      }

      const {
        amount_per_team,
        start_time,
        end_time,
        auction_type,
        players_per_team,
        max_auction_amount,
        created_by,
        max_players_in_season,
        base_player_price,
        ...data
      } = auctionData;
      const modifiedSeason = {
            ...data,
            amountPerTeam: amount_per_team,
            startTime: start_time,
            endTime: end_time,
            auctionType: auction_type,
            playersPerTeam: players_per_team,
            maxAuctionAmount: max_auction_amount,
            createdBy: created_by,
            maxPlayers: max_players_in_season,
            basePlayerPrice: base_player_price,
          };
      dispatch(setAuctions(modifiedSeason));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function applySeasonImage(seasonId: String, image: File) {
  const form = new FormData();
  form.append('cover_image', image);
  return async (dispatch: Dispatch) => {
    try {
      await axios.post(SEASON_IMAGE(seasonId), form);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getGeneratedLinksHistory(type: number, seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(LIST_INVITATION_LINKS(type, seasonId));
      const data = response?.data?.data?.link;
      const restructuredData = data.map((link: any) => {
        const { expire_in, generated_on,auto_approve,max_players, ...rest } = link;
        const modifiedData = { ...rest, expireIn: expire_in, generatedOn: generated_on,autoApprove: auto_approve, maxPlayers:max_players };
        return modifiedData;
      });
      dispatch(setLinkHistory(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listUnsoldUnauctionedPlayers(seasonId: string, page?: number, size?: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(UNAUCTIONED_UNSOLD_SEASON_PLAYERS(seasonId, page, size));
      const modifiedResponse = response?.data?.data?.players.map((unSoldplayer: any) => {
        const { profile_picture, ...rest } = unSoldplayer;
        const modifiedData = { ...rest, profilePicture: profile_picture };
        return modifiedData;
      });
      dispatch(getUnAuctionedPlayers(modifiedResponse));
      dispatch(setUnAuctionedPlayersCount(response?.data?.data?.total_count))
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listSeasonRules(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try{
      dispatch(startLoading());
      const response = await axios.get(SEASON_RULES(seasonId));
      dispatch(setSeasonRules(response?.data?.data?.rules));
    }
    catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  }
}