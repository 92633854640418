import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { TournamentDetails, TournamentType } from 'types/tournaments';
import axios from 'utils/axios';
import { SPORTS_LIST, TOURNAMENTS, TOURNAMENT_DETAILS, TOURNAMENT_IMAGE } from 'api/endpoints';

// ----------------------------------------------------------------------

type Tournaments = Omit<
  TournamentDetails,
  'tournamentStartDate' | 'sportName' | 'cover_image' | 'tagline' | 'id'
>;
type EditTournaments = Omit<
  TournamentDetails,
  | 'auctionDate'
  | 'auctionStatus'
  | 'tournamentStartDate'
  | 'sportName'
  | 'tagline'
  | 'nextSeason'
  | 'seasonsCount'
>;
const initialState: TournamentType = {
  tournamentIsLoading: false,
  hasError: null,
  sports: [],
  tournament: {
    id: '',
    name: '',
    description: '',
    coverImage: null,
    organization: {
      id: '',
      name: '',
      slug: '',
      description: '',
    },
    tag: '',
  },
  tournaments: [],
  tournamentId: '',
  tournamentName: '',
};

const slice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.tournamentIsLoading = true;
      state.hasError = null;
    },
    // STOP LOADING
    stopLoading(state) {
      state.tournamentIsLoading = false;
    },
    // GET SPORTSLIST
    getSports(state, action) {
      state.tournamentIsLoading = false;
      state.sports = action.payload.data;
    },
    // GET A TOURNAMENT
    getATournament(state, action) {
      state.tournamentIsLoading = false;
      state.tournament = action.payload;
    },
    // GET TOURNAMENTS
    getTournaments(state, action) {
      state.tournamentIsLoading = false;
      state.tournaments = action.payload;
    },
    // DELETE TOURNAMENT
    setTournamentAfterDelete(state, action) {
      state.tournamentIsLoading = false;
      state.tournaments = state.tournaments.filter(
        (tournament) => tournament.id !== action.payload
      );
    },
    // SET TOURNAMENT ID AND NAME
    setTournamentID(state, action) {
      state.tournamentId = action.payload;
      state.tournamentName = state.tournaments.find(
        (tournament) => tournament?.id === action.payload
      )?.name;
    },
  },
});
// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  getSports,
  getATournament,
  getTournaments,
  setTournamentAfterDelete,
  setTournamentID,
} = slice.actions;

// ----------------------------------------------------------------------

export function getSportsList() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(SPORTS_LIST);
      dispatch(getSports(response.data));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function tournamentDetail(tournamentId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(TOURNAMENT_DETAILS(tournamentId));
      const { cover_image, is_private, ...rest } = response.data.data;
      const restructuredData = {
        ...rest,
        coverImage: cover_image,
        isPrivateTournament: is_private,
      };
      dispatch(getATournament(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listTournaments(orgId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(TOURNAMENTS, { params: { organization: orgId } });
      const restructuredData: Tournaments[] = response?.data?.data?.map((tourn: any) => {
        const { next_season, cover_image, seasons_count, is_private, ...rest } = tourn;

        const modifiedTourn: Tournaments = {
          ...rest,
          coverImage: cover_image,
          seasonsCount: seasons_count,
          isPrivateTournament: is_private,
          auctionDate: null,
          auctionStatus: '',
          nextSeason: '',
        };

        if (next_season) {
          const { auction_date, auction_status, name } = next_season;
          modifiedTourn.auctionDate = auction_date ? new Date(auction_date) : null;
          modifiedTourn.auctionStatus = auction_status;
          modifiedTourn.nextSeason = name;
        }
        return modifiedTourn;
      });
      dispatch(getTournaments(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function createTournament(tournament: Tournaments) {
  const createTournamentBody: any = {
    name: tournament.name,
    organization_id: tournament.organizationId,
    description: tournament.description,
    sport_id: tournament.sportId,
    tag: tournament.tag,
    sport: tournament.sport,
  };
  if (tournament.isPrivateTournament) createTournamentBody.is_private = true;
  else createTournamentBody.is_private = false;
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(TOURNAMENTS, createTournamentBody);
      const id = response?.data?.data?.id;
      return id;
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function applyTournamentImage(tournamentId: String, image: File) {
  const form = new FormData();
  form.append('cover_image', image);
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(TOURNAMENT_IMAGE(tournamentId), form);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function editTournament(tournament: EditTournaments) {
  const editTournamentBody: {
    name?: string;
    tag?: string;
    description?: string;
    is_private?: boolean;
  } = {
    name: tournament.name,
    tag: tournament.tag,
    description: tournament.description,
    is_private: tournament.isPrivateTournament,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.patch(TOURNAMENT_DETAILS(tournament.id), editTournamentBody);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deleteTournament(tournamentId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.delete(TOURNAMENT_DETAILS(tournamentId));
      dispatch(setTournamentAfterDelete(tournamentId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
