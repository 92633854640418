import { useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, BoxProps, Button, Divider } from '@mui/material';
// hooks
import { useNavigate } from 'react-router';
import { PATH_AUTH } from 'routes/paths';
import { useAuthContext } from 'auth/useAuthContext';
import { useSelector } from 'react-redux';
import AccountPopover from 'layouts/root/header/AccountPopover';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// components
import Logo from '../../components/logo';
//
import navConfig from './nav/config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const navigate = useNavigate();

  const { isAuthenticated } = useAuthContext();

  const { user } = useSelector((state:any) => state.profile);

  return (
    <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && (
            <>
              <NavDesktop isOffset={isOffset} data={navConfig} />
              <Divider orientation="vertical" sx={{ mr: '40px', width: 2, height: 40 }} />
            </>
          )}

          {!isAuthenticated ? (
            <>
              <Button
                variant="contained"
                size="medium"
                sx={{ marginRight: '10px' }}
                onClick={() => navigate(PATH_AUTH.login)}
              >
                Login
              </Button>
              <Button variant="outlined" size="medium" onClick={() => navigate(PATH_AUTH.register)}>
                Sign up
              </Button>
            </>
          ) : (
            <AccountPopover />
          )}

          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
