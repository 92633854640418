import { Dispatch, createSlice } from '@reduxjs/toolkit';
import {
  ADD_PLAYER_TO_TEAM,
  GENERATE_URL,
  INVITE_PLAYERS_LIST,
  LIST_INVITATIONS,
  PLAYERS,
  PLAYERS_IN_CATEGORY,
  PLAYERS_IN_SEASON,
  PLAYERS_IN_SUBCATEGORY,
  PLAYER_REGISTRATION_STATUS,
  RESET_URL,
} from 'api/endpoints';
import { PlayerType } from 'types/player';
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState: PlayerType = {
  isLoading: false,
  players: [],
  totalPlayersInSeason: 0,
  unassignedPlayersCount: 0,
  searchedPlayer: null,
  categoryPlayer: null,
  playerInvitationLink: '',
  invitedPlayers: [],
  playerRegistration: [],
};

const slice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // FETCH PLAYERS
    getPlayers(state, action) {
      state.isLoading = false;
      state.players = action.payload;
    },
    // SET COUNT OF PLAYERS IN A SEASON
    setSeasonPlayersCount(state, action) {
      state.totalPlayersInSeason = action.payload;
    },
    // SET COUNT OF UNASSIGNED PLAYERS IN A SEASON
    setUnassignedPlayersCount(state, action) {
      state.unassignedPlayersCount = action.payload;
    },
    // SET SEARCHED PLAYER DETAILS
    setSearchedPlayer(state, action) {
      state.isLoading = false;
      state.searchedPlayer = action.payload;
    },
    // GET PLAYERS UNDER A CATEGORY
    getCategoryPlayers(state, action) {
      state.isLoading = false;
      state.categoryPlayer = action.payload;
    },
    // SET PLAYER INVITATION LINK
    setPlayerInvitationLink(state, action) {
      state.isLoading = false;
      state.playerInvitationLink = action.payload;
    },
    // GET PLAYER INVITATION DETAILS
    getPlayerInvitationDetails(state, action) {
      state.isLoading = false;
      state.invitedPlayers = action.payload;
    },
    // GET PLAYER REGISTARTION STATUS
    playerRegistrationStatus(state, action) {
      state.isLoading = false;
      state.playerRegistration = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  getPlayers,
  setSeasonPlayersCount,
  setUnassignedPlayersCount,
  setSearchedPlayer,
  getCategoryPlayers,
  setPlayerInvitationLink,
  getPlayerInvitationDetails,
  playerRegistrationStatus,
} = slice.actions;

// ----------------------------------------------------------------------

export function invitePlayer(
  seasonId: string,
  formattedExpireIn: string,
  isEmail: boolean,
  message: string,
  emailOrPhoneArray?: string[],
  subject?: string
) {
  const invitePlayerBody: any = isEmail
    ? {
        season_id: seasonId || '',
        invitee_type: 2,
        invitation_type: 1,
        emails: emailOrPhoneArray,
        message,
        subject,
        expire_in: formattedExpireIn,
      }
    : {
        season_id: seasonId || '',
        invitee_type: 2,
        invitation_type: 2,
        phone_numbers: emailOrPhoneArray,
        message,
        subject,
        expire_in: formattedExpireIn,
      };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(INVITE_PLAYERS_LIST, invitePlayerBody);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listPlayersOfSeason(seasonId: string, page?: number, size?: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(PLAYERS_IN_SEASON(seasonId, page, size));
      const totalCount = response?.data.data.total_count;
      const unassignedPlayersCount = response?.data.data.unassigned_players_count;
      const restructuredData = response.data.data.players.map((player: any) => {
        const { phone_number, profile_picture, ...rest } = player;
        const modifiedPlayer = {
          ...rest,
          phoneNumber: phone_number,
          profilePicture: profile_picture,
        };
        return modifiedPlayer;
      });
      dispatch(getPlayers(restructuredData));
      dispatch(setSeasonPlayersCount(totalCount));
      dispatch(setUnassignedPlayersCount(unassignedPlayersCount));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function searchPlayerByPhoneNumber(phoneNumber: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(PLAYERS, { phone_number: phoneNumber });
      const data = response?.data?.data;
      const modifiedData = {
        id: data.id,
        username: data.username,
        fullname: data.fullname,
        phoneNumber: data.phone_number,
        profilePicture: data.profile_picture,
        sports: data.sports,
        about: data.about,
        category: data.category,
        subCategory: data.subCategory,
      };
      dispatch(setSearchedPlayer(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function addPlayerToSeason(seasonId: string, playerId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      await axios.post(PLAYERS_IN_SEASON(seasonId), {
        player_id: [playerId],
      });
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function addPlayerToTeam(teamId: string, playerId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(ADD_PLAYER_TO_TEAM, {
        team_id: teamId,
        player_id: [playerId],
      });
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function addPlayerToTeamFromSeason(
  teamId: string,
  playerId: string,
  seasonId: string,
  amount: number
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(ADD_PLAYER_TO_TEAM, {
        team_id: teamId,
        player_id: [playerId],
        season_id: seasonId,
        amount,
      });
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listCategoryPlayers(seasonId: string, categoryId?: string, subCategoryId?: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      let response;
      if (subCategoryId !== undefined) {
        response = await axios.get(PLAYERS_IN_SUBCATEGORY(seasonId, subCategoryId));
      } else if (categoryId !== undefined)
        response = await axios.get(PLAYERS_IN_CATEGORY(seasonId, categoryId));

      const restructuredData = response?.data.data.map((player: any) => {
        const { phone_number, profile_picture, sub_category, ...rest } = player;
        const modifiedPlayer = {
          ...rest,
          phoneNumber: phone_number,
          profilePicture: profile_picture,
          subCategory: sub_category,
        };
        return modifiedPlayer;
      });
      dispatch(getCategoryPlayers(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function removePlayersFromCategory(
  catPlayerPayload: any,
  seasonId: string,
  categoryId?: string,
  subCategoryId?: string,
  seasonPlayerspage?: number,
  seasonPlayersRowsPerPage?: number
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      if (subCategoryId !== undefined) {
        await axios.delete(PLAYERS_IN_SUBCATEGORY(seasonId, subCategoryId), {
          data: catPlayerPayload,
        });
      } else if (categoryId !== undefined)
        await axios.delete(PLAYERS_IN_CATEGORY(seasonId, categoryId), { data: catPlayerPayload });

      dispatch(listCategoryPlayers(seasonId, categoryId, subCategoryId));
      dispatch(listPlayersOfSeason(seasonId, seasonPlayerspage, seasonPlayersRowsPerPage));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function addCategoryPlayers(
  selectedPlayers: string[],
  seasonId: string,
  categoryId?: string,
  subCategoryId?: string,
  seasonPlayerspage?: number,
  seasonPlayersRowsPerPage?: number
) {
  const catPlayerPayload = {
    player_id: selectedPlayers,
  };
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      if (subCategoryId !== undefined) {
        await axios.post(PLAYERS_IN_SUBCATEGORY(seasonId, subCategoryId), catPlayerPayload);
      } else if (categoryId !== undefined)
        await axios.post(PLAYERS_IN_CATEGORY(seasonId, categoryId), catPlayerPayload);
      dispatch(listCategoryPlayers(seasonId, categoryId, subCategoryId));
      dispatch(listPlayersOfSeason(seasonId, seasonPlayerspage, seasonPlayersRowsPerPage));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deletePlayersFromASeason(
  id: string,
  seasonId: string,
  seasonPlayerspage: number,
  seasonPlayersRowsPerPage: number
) {
  const deleteTeam: any = {
    player_id: id,
  };
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(startLoading());
      const deleteResponse = await axios.delete(PLAYERS_IN_SEASON(seasonId), {
        data: deleteTeam,
      });
      dispatch(listPlayersOfSeason(seasonId || '', seasonPlayerspage, seasonPlayersRowsPerPage));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function generateURLForPlayer(
  seasonId: string,
  expireDateFormatted: string,
  autoApprove?: boolean,
  maxPlayers?: number
) {
  const detailsToGenLink = {
    season_id: seasonId,
    expire_in: expireDateFormatted,
    invitee_type: 2,
    invitation_type: 3,
    auto_approve: autoApprove,
    max_players: maxPlayers,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(GENERATE_URL, detailsToGenLink);
      dispatch(setPlayerInvitationLink(response?.data?.data?.link));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function ResetPlayerURL(
  seasonId: string,
  expireDateFormatted: string,
  inviteeType: number,
  autoApprove?: boolean,
  maxPlayers?: number
) {
  const detailsToGenLink = {
    season_id: seasonId,
    expire_in: expireDateFormatted,
    invitee_type: inviteeType,
    invitation_type: 3,
    auto_approve: autoApprove,
    max_players: maxPlayers,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(RESET_URL, detailsToGenLink);
      dispatch(setPlayerInvitationLink(response?.data?.data?.link));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function listPlayerInvitations(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(LIST_INVITATIONS(2, seasonId));

      const modifiedSmsData = response?.data?.data.sms.map((smsItem: any) => ({
        phoneNumber: smsItem.phone_number,
        status: smsItem.status,
      }));
      const playerMailData = response?.data?.data.mail.map((mailItem: any) => ({
        email: mailItem.email,
        status: mailItem.status,
      }));

      const restructredResponseData = {
        mail: playerMailData,
        sms: modifiedSmsData,
        link: [],
      };
      dispatch(getPlayerInvitationDetails([restructredResponseData]));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getPlayerRegistrationStatus(seasonId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(PLAYER_REGISTRATION_STATUS(seasonId));
      const modifiedData = response?.data?.data.map((reg: any) => {
        const { invitation_id, user_id, ...rest } = reg;
        const modifiedReg = {
          ...rest,
          invitationId: invitation_id,
          userId: user_id,
        };
        return modifiedReg;
      });
      dispatch(playerRegistrationStatus(modifiedData))
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
