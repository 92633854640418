import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {
  ALL_PLAYERS,
  AUCTION,
  AUCTION_CATEGORIES,
  AUCTION_DETAILS,
  AUCTION_DETAILS_OF_MY_TEAM,
  AUCTION_TEAMS,
  BID_DETAILS,
  CURRENT_AUCTION,
  PRICE_SETTING,
  RANDOM_PLAYER,
  RESTART_AUCTION,
  SET_AUCTION_STATUS,
  SOLD_PLAYERS,
  TEAMS,
  UNDO_BID,
  UNSOLD_PLAYERS,
  UPDATE_PLAYER_STATUS,
  CHECK_AUCTION_READY,
  LATEST_BIDS,
} from 'api/endpoints';
import {
  Auction,
  AuctionCategory,
  AuctionInfo,
  AuctionPlayerDetails,
  AuctionTeamDetail,
  IBidDetails,
  PriceSetting,
} from 'types/auction';
import axios from 'utils/axios';
import { convertToUTC } from 'utils/formatTime';

const initialState: {
  isLoading: boolean;
  isLive: boolean;
  isAuctionLoading: boolean;
  currentAuction: null | Auction;
  auctionInfo: null | AuctionInfo;
  auctionCategories: AuctionCategory[] | null;
  currentPlayer: AuctionPlayerDetails | null;
  soldPlayersList: AuctionPlayerDetails[] | null;
  unsoldPlayersList: AuctionPlayerDetails[] | null;
  allPlayersList: AuctionPlayerDetails[] | null;
  bidsOfPlayer: IBidDetails[] | null;
  teamsInSeason: AuctionTeamDetail[] | null;
  auctionTeamsDetails: AuctionTeamDetail[] | null;
  currentBid: { team: string; amount: number } | null;
  teamDetailedInfo: null | AuctionTeamDetail;
  auctionPriceSettings: PriceSetting[] | null;
  soldPlayerAuctionId: string;
} = {
  isLoading: false,
  isLive: true,
  isAuctionLoading: false,
  currentAuction: null,
  auctionInfo: null,
  auctionCategories: null,
  currentPlayer: null,
  soldPlayersList: null,
  unsoldPlayersList: null,
  allPlayersList: null,
  bidsOfPlayer: null,
  teamsInSeason: null,
  auctionTeamsDetails: null,
  currentBid: null,
  teamDetailedInfo: null,
  auctionPriceSettings: null,
  soldPlayerAuctionId: '',
};

const slice = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING OF AUCTION DETAILS
    startAuctionLoading(state) {
      state.isAuctionLoading = true;
    },
    // Set auction to live
    setIsLive(state) {
      state.isLive = true;
    },
    setIsNotLive(state) {
      state.isLive = false;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.isAuctionLoading = false;
    },
    // SET AUCTION
    setAuction(state, action) {
      state.isLoading = false;
      state.isAuctionLoading = false;
      state.currentAuction = action.payload;
    },
    // SET AUCTION INFO
    setAuctionInfo(state, action) {
      state.isLoading = false;
      state.auctionInfo = action.payload;
    },
    // SET AUCTION CATEGORIES
    setAuctionCategories(state, action) {
      state.isLoading = false;
      state.auctionCategories = action.payload;
    },
    // SET CURRENT PLAYER AUCTIONED
    setPlayer(state, action) {
      state.isLoading = false;
      state.currentPlayer = action.payload;
    },
    // SET SOLD PLAYERS LIST
    setSoldPlayersList(state, action) {
      state.isLoading = false;
      state.soldPlayersList = action.payload;
    },
    // SET UNSOLD PLAYERS LIST
    setUnsoldPlayersList(state, action) {
      state.isLoading = false;
      state.unsoldPlayersList = action.payload;
    },
    // SET ALL PLAYERS LIST
    setAllPlayersList(state, action) {
      state.isLoading = false;
      state.allPlayersList = action.payload;
    },
    setBidsOfPlayer(state, action) {
      state.isLoading = false;
      state.bidsOfPlayer = action.payload;
    },
    setSoldPlayerAuctionId(state, action) {
      state.isLoading = false;
      state.soldPlayerAuctionId = action.payload;
    },
    setTeamsInSeason(state, action) {
      state.isLoading = false;
      state.teamsInSeason = action.payload;
    },
    setAuctionTeamsDetails(state, action) {
      state.isLoading = false;
      state.auctionTeamsDetails = action.payload;
    },
    setCurrentBidDetails(state, action) {
      state.isLoading = false;
      state.currentBid = action.payload;
    },
    setTeamDetailedInfo(state, action) {
      state.isLoading = false;
      state.teamDetailedInfo = action.payload;
    },
    setAuctionPriceSettings(state, action) {
      state.isLoading = false;
      state.auctionPriceSettings = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  startAuctionLoading,
  setIsLive,
  setIsNotLive,
  stopLoading,
  setAuction,
  setAuctionInfo,
  setAuctionCategories,
  setPlayer,
  setSoldPlayersList,
  setUnsoldPlayersList,
  setAllPlayersList,
  setBidsOfPlayer,
  setTeamsInSeason,
  setAuctionTeamsDetails,
  setCurrentBidDetails,
  setTeamDetailedInfo,
  setAuctionPriceSettings,
  setSoldPlayerAuctionId,
} = slice.actions;

// ----------------------------------------------------------------------

export function getAnAuction(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      dispatch(startAuctionLoading());
      const response = await axios.get(AUCTION_DETAILS(auctionId));
      const {
        id,
        auction_type,
        start_time,
        end_time,
        max_players_in_season,
        base_player_price,
        max_auction_amount,
        amount_per_team,
        status,
      } = response.data.data;
      const restructuredData = {
        id: id ?? '',
        auctionType: auction_type,
        startTime: start_time ?? '',
        endTime: end_time ?? '',
        playersPerTeam: max_players_in_season ?? 0,
        playerBasePrice: base_player_price ?? 0,
        maxAuctionAmount: max_auction_amount ?? 0,
        amountPerTeam: amount_per_team ?? 0,
        status: status ?? '',
      };
      dispatch(setAuction(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}
interface AuctionPayload {
  season_id: string | undefined;
  auction_type: string;
  start_time: string | Date;
  end_time: string | Date;
  max_players_in_season: number;
  max_auction_amount?: number;
  player_base_price?: number;
  amount_per_team: number;
}

export function createAuction(createAuctionBody: Auction) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const payload: AuctionPayload = {
        season_id: createAuctionBody.seasonId,
        auction_type: createAuctionBody.auctionType,
        start_time: convertToUTC(createAuctionBody.startTime),
        end_time: convertToUTC(createAuctionBody.endTime),
        max_players_in_season: createAuctionBody.playersPerTeam,
        amount_per_team: createAuctionBody.amountPerTeam,
      };
      if (createAuctionBody.playerBasePrice) {
        payload.player_base_price = createAuctionBody.playerBasePrice;
      }
      if (createAuctionBody.maxAuctionAmount) {
        payload.max_auction_amount = createAuctionBody.maxAuctionAmount;
      }
      const response = await axios.post(AUCTION, payload);
      const {
        auction_type,
        start_time,
        end_time,
        max_players_in_season,
        base_player_price,
        max_auction_amount,
        amount_per_team,
        created_by,
        ...rest
      } = response.data.data;
      const restructuredData = {
        ...rest,
        auctionType: auction_type,
        startTime: start_time ?? '',
        endTime: end_time ?? '',
        playersPerTeam: max_players_in_season ?? 0,
        playerBasePrice: base_player_price ?? 0,
        maxAuctionAmount: max_auction_amount ?? 0,
        amountPerTeam: amount_per_team ?? 0,
        createdBy: created_by ?? '',
      };
      dispatch(setAuction(restructuredData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function updateAuction(data: Partial<Auction>, auctionId: string) {
  const updateAuctionBody = {
    auction_type: data.auctionType,
    amount_per_team: data.amountPerTeam,
    start_time: data.startTime,
    end_time: data.endTime,
    max_players_in_season: data.playersPerTeam,
    max_auction_amount: data.maxAuctionAmount,
    player_base_price: data.playerBasePrice,
  };
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.patch(AUCTION_DETAILS(auctionId), updateAuctionBody);
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function deleteAuction(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.delete(AUCTION_DETAILS(auctionId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getAuctionCategories(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(AUCTION_CATEGORIES(auctionId));
      const data = response?.data.data;
      const modifiedData = data.map((cat: any) => {
        const modyCat = {
          ...cat,
          uuid: cat.season_category_uid,
          name: cat.category_name,
        };
        return modyCat;
      });
      dispatch(setAuctionCategories(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getTeamsInAuction(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(AUCTION_TEAMS(auctionId));
      const data = response?.data?.data;
      const modifiedData = data?.map((teamDets: any) => {
        const modifiedTeamDets = {
          name: teamDets.team_name,
          location: teamDets.location,
          currentPlayers: teamDets.current_players?.map((player: any) => {
            const modiPlayer = {
              id: player.seasom_player_id ?? '',
              player: {
                userId: player.player_id,
                fullName: player.player_name,
                profilePicture: player.profile_image,
                category: null,
                subCategory: null,
              },
              status: 2,
              currentTeam: player.current_team ?? '',
              currentPrice: player.sold_bid ?? 0,
              details: player.sports_details ?? null,
            };
            return modiPlayer;
          }),
          purseLeft: Number(teamDets.purse_left ?? 0),
          totalAmountSpent: Number(teamDets.total_amount_spent ?? 0),
          playersRequired: teamDets.players_required ?? 0,
          playersPerTeam: teamDets.players_per_team ?? 0,
          id: teamDets.id ?? '',
          coverImage: teamDets.team_cover_image ?? null,
        };
        return modifiedTeamDets;
      });
      dispatch(setAuctionTeamsDetails(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getTeamsForAuction(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(TEAMS, { params: { auction: auctionId } });
      const data = response?.data?.data;
      const modifiedData = data?.map((teamDets: any) => {
        const modifiedTeamDets = {
          name: teamDets.name,
          location: teamDets.location,
          coverImage: teamDets.cover_image,
          currentPlayers: teamDets.players?.map((player: any) => {
            const modiPlayer = {
              id: player.player.user_id,
              player: {
                userId: player.player.user_id,
                fullName: player.player.full_name,
                profilePicture: player.player.profile_picture,
                category: null,
                subCategory: null,
              },
              status: 2,
              currentTeam: player.current_team ?? '',
              currentPrice: player.sold_bid ?? 0,
              details: player.sports_details ?? null,
            };
            return modiPlayer;
          }),
          purseLeft: Number(teamDets.purse_left ?? 0),
          totalAmountSpent: Number(teamDets.total_amount_spent ?? 0),
          playersRequired: teamDets.players_required ?? 0,
          playersPerTeam: teamDets.players_per_team ?? 0,
          id: teamDets.id ?? '',
        };
        return modifiedTeamDets;
      });
      dispatch(setTeamsInSeason(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getRandomPlayer(auctionId: string, catId?: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      if (catId) {
        const response = await axios.post(RANDOM_PLAYER(auctionId), {
          category_id: catId,
        });
        const data = response?.data.data;
        const modifiedData = data
          ? {
              ...data,
              player: {
                userId: data.player.user_id,
                fullName: data.player.full_name,
                profilePicture: data.player.profile_picture,
              },
              currentTeam: data.current_team,
              currentPrice: data.current_price,
              status: data.status,
              remainingPlayersCount: data.remaining_players_count,
            }
          : null;
        dispatch(setPlayer(modifiedData));
      } else {
        const response = await axios.post(RANDOM_PLAYER(auctionId));
        const data = response?.data.data;
        const modifiedData = data
          ? {
              ...data,
              player: {
                userId: data.player.user_id,
                fullName: data.player.full_name,
                profilePicture: data.player.profile_picture,
              },
              currentTeam: data.current_team,
              currentPrice: data.current_price,
              remainingPlayersCount: data.remaining_players_count,
            }
          : null;
        dispatch(setPlayer(modifiedData));
      }
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function createABid(playerId: string, bidDetails: { team_id: string }) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(BID_DETAILS(playerId), bidDetails);
      const currentBidDets = response?.data?.data;
      if (currentBidDets) {
        await dispatch(
          setCurrentBidDetails({
            team: currentBidDets.team_name,
            amount: currentBidDets.amount,
            id: currentBidDets.id,
          })
        );
      }
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function updatePlayer(auctionId: string, playerId: string, status: number) {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch(startLoading());
      if (status !== 3) {
        const currentBidTeam = getState().auction.currentBid.team;
        const currentTeam = getState().auction.teamsInSeason.find(
          (team: AuctionTeamDetail) => team.name === currentBidTeam
        );
        const currentTeamId = currentTeam.id;
        await axios.patch(UPDATE_PLAYER_STATUS(auctionId, playerId), {
          status,
          team_id: currentTeamId,
        });
      }
      if (status === 3) {
        await axios.patch(UPDATE_PLAYER_STATUS(auctionId, playerId), {
          status,
        });
      }
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getSoldPlayersList(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(SOLD_PLAYERS(auctionId));
      const data = response?.data.data;
      const modifiedData = data?.map((playerDet: any) => {
        const modyPlayer = {
          ...playerDet,
          player: {
            userId: playerDet.player.user_id,
            fullName: playerDet.player.full_name,
            profilePicture: playerDet.player.profile_picture,
          },
          currentTeam: playerDet.team.name,
          currentPrice: playerDet.auction_amount,
          status: playerDet.status,
        };
        return modyPlayer;
      });
      dispatch(setSoldPlayersList(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getUnsoldPlayersList(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(UNSOLD_PLAYERS(auctionId));
      const data = response?.data.data;
      const modifiedData = data?.map((playerDet: any) => {
        const modyPlayer = {
          ...playerDet,
          player: {
            userId: playerDet.player.user_id,
            fullName: playerDet.player.full_name,
            profilePicture: playerDet.player.profile_picture,
          },
          currentTeam: playerDet.current_team,
          currentPrice: playerDet.current_price,
          status: playerDet.status,
        };
        return modyPlayer;
      });
      dispatch(setUnsoldPlayersList(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getAllPlayersList(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(ALL_PLAYERS(auctionId));
      const data = response?.data.data;
      const modifiedData = data?.map((playerDet: any) => {
        const modyPlayer = {
          id: playerDet.id ?? '',
          player: {
            userId: playerDet.player?.user_id ?? '',
            fullName: playerDet.player?.full_name ?? '',
            profilePicture: playerDet.player?.profile_picture,
          },
          details: playerDet.details,
          currentTeam: playerDet.team?.name,
          currentPrice: playerDet.auction_amount,
          status: playerDet.status,
        };
        return modyPlayer;
      });
      dispatch(setAllPlayersList(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getBidsOfPlayer(playerId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(BID_DETAILS(playerId));
      const responseData = response?.data?.data;
      const modifiedData = responseData?.map((bidDet: any) => {
        const modBid = {
          team: bidDet.team_name,
          amount: bidDet.amount,
          id: bidDet.id,
        };
        return modBid;
      });
      dispatch(setBidsOfPlayer(modifiedData));
      dispatch(setCurrentBidDetails(modifiedData?.[0]));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function restartAuction(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(RESTART_AUCTION(auctionId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getPriceSettingDetails(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(PRICE_SETTING(auctionId));
      const modifiedData = response?.data?.data?.map((priceSet: any) => {
        const modPriceSet: PriceSetting = {
          start: priceSet.start,
          end: priceSet.end,
          incrementValue: priceSet.increment_value,
        };
        return modPriceSet;
      });
      dispatch(setAuctionPriceSettings(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function priceSettingAPI(auctionId: string, currentPriceSettings: PriceSetting[]) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const priceSettingBody = currentPriceSettings.map((range) => {
        const modRange = {
          start: range.start,
          end: range.end,
          increment_value: range.incrementValue,
        };
        return modRange;
      });
      await axios.post(PRICE_SETTING(auctionId), {
        bid_ranges: priceSettingBody,
      });
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getAuctionDetailsOfMyTeam(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(AUCTION_DETAILS_OF_MY_TEAM(auctionId));
      const data = response?.data?.data[0];
      const modifiedData = {
        name: data.team_name,
        location: data.location,
        currentPlayers: data.current_players.map((player: any) => {
          const modiPlayer = {
            id: player.seasom_player_id,
            player: {
              userId: player.player_id,
              fullName: player.player_name,
              profilePicture: player.profile_image,
              category: null,
              subCategory: null,
            },
            status: 2,
            currentTeam: '',
            currentPrice: player.sold_bid,
            details: player.sports_details,
          };
          return modiPlayer;
        }),
        purseLeft: Number(data.purse_left),
        totalAmountSpent: Number(data.total_amount_spent),
        playersRequired: data.players_required,
        playersPerTeam: data.players_per_team ?? 0,

        id: '',
      };
      dispatch(setTeamDetailedInfo(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function undoBidAPI(playerId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(UNDO_BID(playerId));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getCurrentAuctionDetails(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(CURRENT_AUCTION(auctionId));
      const data = response.data?.data;
      const modifiedData: any = {
        auctionPlayer: {
          userId: data.player.user_id,
          fullName: data.player.full_name,
          phoneNumber: data.player.phone_number,
          profilePicture: data.player.profile_picture,
          seasonPlayerId: data.season_player_id,
        },
        details: data.details,
        categoryId: data.category_id ?? '',
      };
      if (data.team) {
        modifiedData.team = {
          uuid: data.team.uid,
          name: data.team.name,
          location: data.team.location,
        };
      }
      if (data.amount) {
        modifiedData.amount = data.amount;
      }
      dispatch(setAuctionInfo(modifiedData));
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function updateAuctionStatus(auctionId: string, status:string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(SET_AUCTION_STATUS(auctionId), { status });
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function checkIfSeasonReady(seasonId: string, auctionType: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startLoading());
      await axios.post(CHECK_AUCTION_READY(seasonId), { auction_type: auctionType });
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  };
}

export function getLatestBids(auctionId: string) {
  return async (dispatch: Dispatch) => {
    try{
      dispatch(startLoading());
      await axios.get(LATEST_BIDS(auctionId));
      dispatch(stopLoading());
    }
    catch (err) {
      dispatch(stopLoading());
      throw err;
    }
  }
}
