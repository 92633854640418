export const REGISTER_ENDPOINT = `/api/users/register/`;

export const LOGIN_ENDPOINT = `/api/users/login/`;

export const PASSWORDLESS_REGISTER = 'api/users/register/without_password/'

export const LOGOUT_ENDPOINT = `/api/users/logout/`;

export const VERIFY_NUMBER = `/api/users/verify-number/`;

export const VERIFY_OTP = `/api/users/verify-otp/`;

export const RESEND_OTP = `/api/users/resend-otp/`;

export const USER_DETAILS = `/api/users/profile/`;

export const PROFILE_IMAGE = `/api/users/upload-image/`;

export const DASHBOARD_DETAILS = `/api/users/dashboard/`;

export const PLAYER_DETAILS = playerId => `api/players/${playerId}/`;

export const MY_SPORT_DETAILS = `/api/users/sports/`;

export const GET_SPORT_LABELS = `/api/users/sports-profile-labels/`;

export const SPORTS_LIST = `/api/tournaments/sports/`;

export const TOURNAMENT_DETAILS = tournamentId => `/api/tournaments/${tournamentId}/`;

export const TOURNAMENTS = `/api/tournaments/`;

export const TOURNAMENT_IMAGE = tournamentId => `/api/tournaments/cover-image/${tournamentId}`;

export const TEAMS = `/api/teams/`;

export const TEAM_IMAGE = teamId => `/api/teams/cover-image/${teamId}`;

export const TEAM_DETAILS = teamId => `/api/teams/${teamId}/`;

export const SEASON_TEAM_PLAYERS =  `/api/seasons/team_players/`;

export const TEAM_UNDER_MANAGER = teamId => `api/teams/managed/teams/${teamId}/`;

export const TEAMS_UNDER_MANAGER = `api/teams/managed/teams/`;

export const ADD_PLAYER_TO_TEAM = '/api/teams/players/'

export const TEAMS_IN_SEASON = seasonId => `/api/seasons/${seasonId}/teams/`;

export const INVITE_TEAMS = `/api/seasons/invite/teams/`;

export const LIST_INVITATIONS =  (inviteeType, seasonId) => `/api/seasons/invitations/?type=${inviteeType}&season_id=${seasonId}`

export const GET_INVITATION_DETAILS = (invitationId) => `/api/seasons/invitations/${invitationId}`;

export const UPDATE_INVITATION_STATUS = `api/seasons/invitation_status/`

export const ACCEPT_INVITE = 'api/seasons/invitation_accept/'

export const REJECT_INVITE = 'api/seasons/invitation_reject/'

export const LIST_INVITATION_LINKS = (type, seasonId) =>  `/api/seasons/list_invitation_links/?type=${type}&season_id=${seasonId}`

export const TEAM_REGISTRATION_STATUS = (seasonId) => `api/seasons/registration/list/?type=1&season_id=${seasonId}`;

export const PLAYER_REGISTRATION_STATUS = (seasonId) =>  `api/seasons/registration/list/?type=2&season_id=${seasonId}`;

export const GENERATE_URL = `/api/seasons/invite/`;

export const UPDATE_URL = `/api/seasons/invite/teams/`;

export const RESET_URL = `/api/seasons/invite/reset/`

export const SEASONS = `/api/seasons/`;

export const SEASON_DETAILS = seasonId => `/api/seasons/${seasonId}/`;

export const SEASON_AUCTION_LIST = seasonId => `/api/auctions/?season=${seasonId}`;

export const SEASON_IMAGE = seasonId => `/api/seasons/cover-image/${seasonId}`;

export const SEASON_RULES = seasonId => `api/seasons/${seasonId}/rules`;

export const INVITE_PLAYERS_LIST = `api/seasons/invite/players/`;

export const PLAYERS_IN_SEASON = (seasonId,page,size) => `/api/seasons/${seasonId}/players/?page=${page}&size=${size}`;

export const UNAUCTIONED_UNSOLD_SEASON_PLAYERS = (seasonId,page,size) => `api/seasons/${seasonId}/unsold_players/?page=${page}&size=${size}`;

export const PLAYERS = `/api/players/`;

export const PLAYERS_IN_CATEGORY = (seasonId, categoryId) => `/api/seasons/${seasonId}/category/${categoryId}/players/`;

export const PLAYERS_IN_SUBCATEGORY = (seasonId, subCategoryId) => `/api/seasons/${seasonId}/category/${subCategoryId}/players/`;

export const ORGANIZATIONS_LIST = `/api/organizations/list/`;

export const ORGANIZATION = `/api/organizations/`;

export const ORGANIZATION_DETAILS = orgId => `/api/organizations/${orgId}/`;

export const ORGANIZATION_IMAGE = orgId => `/api/organizations/cover-image/${orgId}`;

export const CATEGORY_DETAILS = seasonId => `/api/seasons/${seasonId}/category/`;

export const SUBCATEGORY_DETAILS = (seasonId, categoryId) => `/api/seasons/${seasonId}/category/${categoryId}/`;

export const AUCTION_DETAILS = auctionId => `/api/auctions/${auctionId}/`;

export const AUCTION = `/api/auctions/`;

export const AUCTION_CATEGORIES = auctionId => `/api/auctions/${auctionId}/category/`;

export const AUCTION_TEAMS = auctionId => `/api/teams/team_list_details/${auctionId}/`;

export const RANDOM_PLAYER = auctionId => `/api/auctions/${auctionId}/players/`;

export const BID_DETAILS = playerId => `/api/auctions/players/${playerId}/bids/`;

export const UPDATE_PLAYER_STATUS = (auctionId, playerId) => `/api/auctions/${auctionId}/players/${playerId}/`;

export const SOLD_PLAYERS = auctionId => `/api/auctions/${auctionId}/players/sold/`;

export const ALL_PLAYERS = auctionId => `/api/auctions/${auctionId}/auction_players/`;

export const UNSOLD_PLAYERS = auctionId => `/api/auctions/${auctionId}/players/`;

export const RESTART_AUCTION = auctionId => `/api/auctions/restart/${auctionId}/`;

export const PRICE_SETTING = auctionId => `/api/auctions/${auctionId}/price_setting/`;

export const AUCTION_DETAILS_OF_MY_TEAM = auctionId => `/api/auctions/${auctionId}/team_details/`;

export const UNDO_BID = playerId => `/api/auctions/players/${playerId}/bids/undo-bid/`;

export const CURRENT_AUCTION = auctionId => `/api/auctions/${auctionId}/info/`;

export const SET_AUCTION_STATUS = auctionId => `/api/auctions/${auctionId}/update_auction_status/`;

export const CHECK_AUCTION_READY = seasonId => `/api/seasons/${seasonId}/check-auction-creation/`;

export const LATEST_BIDS = auctionId => `/api/auctions/all_bids/${auctionId}/`
