import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import RootLayout from '../layouts/root/RootLayout';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LandingPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  RegisterWithoutPasswordPage,
  //
  GeneralAppPage,
  //
  TournamentsListPage,
  TournamentsCreatePage,
  //
  SeasonsListPage,
  SeasonsCreatePage,
  SeasonEditPage,
  SeasonWelcomePage,
  //
  SeasonDetailsPage,
  TournamentsEditPage,
  TeamAddCreatePage,
  TeamEditPage,
  TeamDetailsPage,
  TeamInvitation,
  //
  PlayerDetailsPage,
  PlayersAddInvitePage,
  PlayerInvitation,
  //
  UserProfilePage,
  UserAccountPage,
  EditSportsDetailPage,
  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  CreateAuctionPage,
  LiveAuctionPage,
  // SeasonsDetailsPage,
  OrganizationPage,
  OrganizationCreatePage,
  OrganizationEditPage,
  CreateCategoryPage,
  CategoryPlayersPage,
  PlayerBidsHistoryPage,
  CreateTeamFromNavbar,
  AddPlayersToSeasonTeam,
  InvitationContent,
} from './elements';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register-passwordless',
          element: (
            <GuestGuard>
              <RegisterWithoutPasswordPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: (
            <GuestGuard>
              <CompactLayout/>
            </GuestGuard>
        ),
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    // Dashboard
    {
      path: '/',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <GeneralAppPage />, errorElement: <Page500 /> },
        { path: '/live-auction/:auctionId/:view', element: <LiveAuctionPage /> },
        { path: 'teams/', element: <CreateTeamFromNavbar /> },
        { path: 'teams/:teamId/players/add', element: <PlayersAddInvitePage /> },
        { path: 'teams/:teamId', element: <TeamDetailsPage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'account/:name', element: <UserAccountPage /> },
            { path: 'sports-detail', element: <EditSportsDetailPage /> },
          ],
        },
        // player profile
        {
          path: 'player',
          children: [
            { path: ':id/profile', element: <UserProfilePage /> },
            { path: ':playerId/bids-history', element: <PlayerBidsHistoryPage /> },
            // { path: ':id/sports-detail', element: <EditSportsDetailPage /> },
          ],
        },
        // organization
        {
          path: 'organization',
          children: [
            { element: <Navigate to="/organization/list" replace />, index: true },
            { path: 'list', element: <OrganizationPage /> },
            { path: 'new', element: <OrganizationCreatePage /> },
            { path: 'edit/:id', element: <OrganizationEditPage /> },
          ],
        },
      ],
    },
    // Tournaments
    {
      path: '/',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'tournaments', element: <TournamentsListPage />, index: true },
        { path: 'tournament/new', element: <TournamentsCreatePage /> },
        { path: 'tournament/:tournamentId/edit', element: <TournamentsEditPage /> },
      ],
    },
    // Seasons
    {
      path: 'tournaments/:tournamentId/',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'seasons', element: <SeasonsListPage />, index: true },
        { path: 'seasons/:seasonId/edit', element: <SeasonEditPage /> },
        { path: 'seasons/new', element: <SeasonsCreatePage /> },
        { path: 'seasons/:seasonId', element: <SeasonDetailsPage /> },
        { path: 'seasons/:seasonId/welcome', element: <SeasonWelcomePage />},
      ],
    },
    // Teams
    {
      path: 'seasons/:seasonId',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'teams/new', element: <TeamAddCreatePage /> },
        { path: 'teams/:teamId/edit', element: <TeamEditPage /> },
        { path: 'teams/invite', element: <TeamInvitation /> },
        { path: 'teams/:teamId', element: <TeamDetailsPage /> },
        { path: 'teams/:teamId/players/add', element: <AddPlayersToSeasonTeam /> },
        //  { path: 'teams/:teamId/players/add', element: <PlayersAddInvitePage /> },
      ],
    },
    // Players
    {
      path: 'seasons/:seasonId',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'players/new', element: <PlayersAddInvitePage /> },
        { path: ':title', element: <PlayerDetailsPage /> },
        { path: 'players/invite', element: <PlayerInvitation /> },
      ],
    },
    // Auction
    {
      path: 'seasons/:seasonId/auction/',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'new', element: <CreateAuctionPage false /> },
        { path: ':auctionId', element: <CreateAuctionPage isEdit /> },
      ],
    },
    // category
    {
      path: '/seasons/:seasonId',
      element: (
        <AuthGuard>
          <RootLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'category/new', element: <CreateCategoryPage /> },
        { path: 'category/:categoryId/players', element: <CategoryPlayersPage /> },
        {
          path: 'subcategory/:subCategoryId/players',
          element: <CategoryPlayersPage />,
        },
      ],
    },
    // INVITATION
    {
      path: '/',
      element: (
        <MainLayout />
      ),
      children: [
        { path: 'publicInvitation/:type/:code', element: <InvitationContent isPublic />, index: true },
        { path: 'invitation/:type/:code', element: <InvitationContent />, index: true },
      ],
    },
    // Main Routes
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <LandingPage />, index: true}, 
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
