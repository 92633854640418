import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/store';
import { clearInviteDetails, updateInvitationStatus } from 'redux/slices/invitation';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from '../routes/paths';
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const { inviteId, redirectTo } = useSelector((state) => state.invitation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [isInviteProcessing, setIsInviteProcessing] = useState(false);

  useEffect(() => {
    const acceptInvite = async () => {
      if (inviteId && redirectTo) {
        try {
          setIsInviteProcessing(true);
          await dispatch(updateInvitationStatus(inviteId, 'accept'));
          dispatch(clearInviteDetails());
          navigate(redirectTo, { replace: true });
        } catch (error) {
          enqueueSnackbar('Failed to join season', {variant: 'error'});
        } finally {
          setIsInviteProcessing(false);
        }
      }
    };

    if (isAuthenticated && inviteId && !isInviteProcessing) {
      acceptInvite();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inviteId, redirectTo, dispatch, navigate, isInviteProcessing]);

  useEffect(() => {
    if (isAuthenticated && !inviteId && redirectTo) {
      navigate(redirectTo, { replace: true });
    }
  }, [isAuthenticated, inviteId, redirectTo, navigate]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && isInviteProcessing) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    if (redirectTo && !inviteId) {
      return null; 
    }
    return <Navigate to={PATH_DASHBOARD.root} replace />;
  }

  return <>{children}</>;
}
