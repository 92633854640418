import { Suspense, lazy, ElementType } from 'react';
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LandingPage = Loadable(lazy(() => import('../pages/auth/LandingPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const RegisterWithoutPasswordPage = Loadable(lazy(() => import('../pages/auth/RegisterWithoutPassword')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/user/UserProfilePage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/user/UserAccountPage')));
export const EditSportsDetailPage = Loadable(
  lazy(() => import('../pages/user/EditSportsDetailPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// ORGANIZATION
export const OrganizationPage = Loadable(
  lazy(() => import('../pages/organization/OrganizationPage'))
);
export const OrganizationCreatePage = Loadable(
  lazy(() => import('../pages/organization/OrganizationCreatePage'))
);
export const OrganizationEditPage = Loadable(
  lazy(() => import('../pages/organization/OrganizationEditPage'))
);
// TOURNAMENTS
export const TournamentsListPage = Loadable(
  lazy(() => import('../pages/tournaments/TournamentsListPage'))
);
export const TournamentsCreatePage = Loadable(
  lazy(() => import('../pages/tournaments/TournamentsCreatePage'))
);
export const TournamentsEditPage = Loadable(
  lazy(() => import('../pages/tournaments/TournamentsEditPage'))
);

// SEASONS
export const SeasonsListPage = Loadable(lazy(() => import('../pages/seasons/SeasonsListPage')));
// export const SeasonsDetailsPage = Loadable(lazy(() => import ('../pages/seasons/SeasonsDetailsPage')))
export const SeasonsCreatePage = Loadable(lazy(() => import('../pages/seasons/SeasonsCreatePage')));
export const SeasonEditPage = Loadable(lazy(() => import('../pages/seasons/SeasonEditPage')));
export const SeasonDetailsPage = Loadable(lazy(() => import('../pages/seasons/SeasonDetailsPage')));
export const SeasonWelcomePage = Loadable(lazy(() => import('../pages/seasons/SeasonWelcomePage')));
// TEAMS
export const CreateTeamFromNavbar = Loadable(
  lazy(() => import('../pages/teams/TeamsNavBar/CreateTeamsFromNavbar'))
);
export const AddPlayersToSeasonTeam = Loadable(
  lazy(() => import('../pages/teams/AddPlayerToSeasonTeam'))
);
export const TeamDetailsPage = Loadable(lazy(() => import('../pages/teams/TeamDetailsPage')));
export const TeamAddCreatePage = Loadable(lazy(() => import('../pages/teams/TeamAddCreatePage')));
export const TeamEditPage = Loadable(lazy(() => import('../pages/teams/TeamEditPage')));
export const TeamInvitation = Loadable(
  lazy(() => import('../pages/teams/invitation/TeamInvitation'))
);
// PLAYERS
export const PlayerDetailsPage = Loadable(lazy(() => import('../pages/players/PlayerDetailsPage')));
export const PlayersAddInvitePage = Loadable(
  lazy(() => import('../pages/players/PlayersAddInvitePage'))
);
export const PlayerInvitation = Loadable(
  lazy(() => import('../pages/players/invitation/PlayerInvitation'))
);

// AUCTION
export const CreateAuctionPage = Loadable(lazy(() => import('../pages/auction/CreateAuctionPage')));
// CATEGORY
export const CreateCategoryPage = Loadable(
  lazy(() => import('../pages/category/CreateCategoryPage'))
);
export const CategoryPlayersPage = Loadable(
  lazy(() => import('../pages/category/CategoryPlayersPage'))
);
export const LiveAuctionPage = Loadable(lazy(() => import('../pages/auction/LiveAuctionPage')));

// BIDS
export const PlayerBidsHistoryPage = Loadable(
  lazy(() => import('../pages/auction/bids-history/PlayerBidsHistory'))
);

// INVITATION
export const InvitationContent = Loadable(
  lazy(() => import('../components/season-invite/InvitationLetter/InvitationContent'))
);
