import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/store';
import { getAccountDetails } from 'redux/slices/user';
import { PATH_AUTH } from 'routes/paths';
import axios from 'utils/axios';
import localStorageAvailable from 'utils/localStorageAvailable';
import { LOGIN_ENDPOINT, LOGOUT_ENDPOINT, PASSWORDLESS_REGISTER, REGISTER_ENDPOINT } from 'api/endpoints';
import { RESET_STATE } from 'redux/rootReducer';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, JWTContextType } from './types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
  };
  [Types.LOGIN]: {};
  [Types.REGISTER]: {};
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const reduxDispatch = useDispatch();

  const storageAvailable = localStorageAvailable();

  const getUserAccountDetails = async () => {
    await reduxDispatch(getAccountDetails());
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const refreshToken = storageAvailable ? localStorage.getItem('refreshToken') : '';

      if (accessToken && isValidToken(accessToken) && refreshToken && isValidToken(refreshToken)) {
        setSession(accessToken, refreshToken);
        await getUserAccountDetails();
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
          },
        });
      }
    } catch (error) {
      console.error(error);
      <Navigate to={PATH_AUTH.login} replace />;
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (phone_number: string, password: string) => {
    await axios
      .post(LOGIN_ENDPOINT, {
        phone_number,
        password,
      })
      .then(async (response) => {
        const accessToken = response.data?.data?.tokens?.access || null;
        const refreshToken = response.data?.data?.tokens?.refresh || null;
        setSession(accessToken, refreshToken);
        await getUserAccountDetails();
        dispatch({
          type: Types.LOGIN,
          payload: {},
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      password: string,
      phone_number: string,
      email: string | null = null,
      first_name: string | null = null,
      last_name: string | null = null
    ) => {
      await axios.post(REGISTER_ENDPOINT, {
        password,
        phone_number,
        ...(email && { email }),
        ...(first_name && { first_name }),
        ...(last_name && { last_name }),
      });
    },
    []
  );

  const registerWithoutPassword = useCallback(
    async (
      phone_number: string,
      first_name: string | null = null,
      last_name: string | null = null
    ) => {
      await axios.post(PASSWORDLESS_REGISTER, {
        phone_number,
        ...(first_name && { first_name }),
        ...(last_name && { last_name }),
      }).then(async (response) => {
        const accessToken = response.data?.data?.tokens?.access || null;
        const refreshToken = response.data?.data?.tokens?.refresh || null;
        setSession(accessToken, refreshToken);
        await getUserAccountDetails();
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
          },
        });
      });;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null, null);
    dispatch({
      type: Types.LOGOUT,
    });
    await reduxDispatch({ type: RESET_STATE });
    await axios.get(LOGOUT_ENDPOINT, {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      method: 'jwt',
      login,
      register,
      logout,
      registerWithoutPassword,
    }),
    [state.isInitialized, state.isAuthenticated, login, register, logout, registerWithoutPassword]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
