import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_PAGE } from 'routes/paths';
import LoadingScreen from 'components/loading-screen';
import { useDispatch } from 'redux/store';
import { clearInviteDetails } from 'redux/slices/invitation';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const dispatch = useDispatch();


  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }
  
  dispatch(clearInviteDetails());

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_PAGE.landingPage} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
